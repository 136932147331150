import logo from './logo.svg';
import './App.css';
import { ContextProvider } from './context/ContextProvider';
import ExamplePlugin from './views/ExamplePlugin';
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <Router>
    <ContextProvider>
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <span>
            Demo Plugin
          </span>
        </header>
        <ExamplePlugin/>
      </div>
    </ContextProvider>
    </Router>
  );
}

export default App;
